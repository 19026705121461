import $ from "jquery";

export const initMenuFunctions = () => {
  // set the mobile menu hamburger icon
  $(document).on("click", ".hamburger", function () {
    $(this).toggleClass("is-active");

    if ($(".mobile-menu").hasClass("hidden")) {
      $(".mobile-menu").removeClass("hidden slideOutRight");
      $(".mobile-menu").addClass("slideInRight");
      $(".mobile-menu-overlay").removeClass("hidden");
      $("body").addClass("no-scroll");
    } else {
      $(".mobile-menu").removeClass("slideInRight");
      $(".mobile-menu").addClass("slideOutRight");
      $(".mobile-menu-overlay").addClass("hidden");
      $("body").removeClass("no-scroll");

      setTimeout(() => {
        $(".mobile-menu").addClass("hidden");
      }, 300);
    }
  });

  // Close menu when clicking overlay
  $(document).on("click", ".mobile-menu-overlay", function() {
    $(".hamburger").removeClass("is-active");
    $(".mobile-menu").removeClass("slideInRight");
    $(".mobile-menu").addClass("slideOutRight");
    $(".mobile-menu-overlay").addClass("hidden");
    $("body").removeClass("no-scroll");

    setTimeout(() => {
      $(".mobile-menu").addClass("hidden");
    }, 300);
  });

  // accessibility - open children menu on enter key
  $(document).keyup(function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      $(e.target).parent(".has-children").addClass("open");
    }
  });

  // accessibility - close children menu on esc key
  $(document).keyup(function (e) {
    if (e.keyCode === 27 && $(".has-children").hasClass("open")) {
      e.preventDefault();
      $(".has-children").removeClass("open");
    }
  });

  // Add this to your initMenuFunctions
  $(document).on("click", ".submenu-toggle", function(e) {
    e.preventDefault();
    const $button = $(this);
    const $submenu = $button.closest('li').find('.mobile-sub-menu');

    // Toggle active state of button
    $button.toggleClass('active');

    // Close other submenus first
    $('.mobile-sub-menu').not($submenu).slideUp(300);
    $('.submenu-toggle').not($button).removeClass('active');

    // Toggle current submenu
    $submenu.slideToggle(300);
  });
};

export default initMenuFunctions;
