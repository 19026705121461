import { createApp } from 'vue';
import Commerce7Collection from '../vue/collection/Commerce7.vue';
import Commerce7Product from '../vue/product/Product.vue';

export const initVue = () => {
    // Initialize Collection app
    const collectionApp = createApp(Commerce7Collection);
    collectionApp.mount('#wine-collection-container');

    // Initialize Product app
    const productApp = createApp(Commerce7Product);
    productApp.mount('#wine-product-container');
};

export default initVue;
