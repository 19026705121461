import { createApp } from 'vue';
import Commerce7Collection from '../vue/collection/Commerce7.vue';

export const initVue = () => {
    // Initialize Collection app
    const collectionApp = createApp(Commerce7Collection);
    collectionApp.mount('#wine-collection-container');
};

export default initVue;
