export const initUtilities = () => {
  // formie CSRF token autocomplete remove
  $('input[name="CRAFT_CSRF_TOKEN"]').removeAttr('autocomplete');

  // FAQ Questions
  $(document).on('click', '.faq-question', function () {
    $(this).toggleClass('open');
    $(this).next().slideToggle(200);
  });

  $(document).on('keydown', '.faq-question', function (e) {
    if (e.key === 'Enter') {
      $(this).trigger('click');
    }
  });

  // MAP BLOCK
  $('.list-region-selector').on('click', function (event) {
    // event.preventDefault();
    $('.list-region-selector').removeClass('active');
    $(this).addClass('active');
    const regionTitle = $(this).data('title');
    const regionInfoDiv = $('#information-' + regionTitle + '-list');
    $('.region-information-list').addClass('hidden');
    regionInfoDiv.removeClass('hidden');
  });

  $('.mobile-region-selector').on('click', function (event) {
    event.preventDefault();
    $('.mobile-region-selector').removeClass('active');
    $(this).addClass('active');
    const regionTitle = $(this).data('title');
    const regionInfoDiv = $('#information-' + regionTitle + '-mobile');
    $('.region-information-mobile').hide();
    regionInfoDiv.show();
  });

  $(document).on('click', '.region-pin', function () {
    let region = $(this).data('title');
    let regionElement = $('#' + region);
    $('.cls-2').removeClass('active');
    regionElement.addClass('active');
    $(this).addClass('active');

    // Show the overlay
    let regionInfoElement = $('#information-' + region);
    regionInfoElement.show();

    // Hide all other cards
    $('.winery-overlay').show();
    $('.card-information').hide();
    $('#card-' + region).show();

    let offSetElement = $('.offset-' + region);

    $('html, body').animate({
      scrollTop:
        offSetElement.offset().top -
        $(window).height() / 2 +
        offSetElement.height() / 2,
    });

    // add animation to the regioninfoelement on show
    $('.region-information-container')
      .removeClass('slideOutRight')
      .addClass('slideInRight');
  });

  $(document).on('click', '.region-information', function (e) {
    if ($(e.target).closest('.region-information-container').length === 0) {
      $('.cls-2').removeClass('active');
      $('.region-pin').removeClass('active');
      $('.region-information-container')
        .removeClass('slideInRight')
        .addClass('slideOutRight');

      setTimeout(() => {
        $('.region-information').hide();
      }, 300);
    }
  });

  // if card-information is shown, hide it on click outside
  $(document).on('click', function (event) {
    const card = $('.card-information:visible');
    if (card.length && !card.is(event.target)) {
      card.addClass('hidden');
    }
  });

  $(document).on('click', '.close-btn', function () {
    $('.cls-2').removeClass('active');
    $('.region-pin').removeClass('active');
    $('.region-information-container')
      .removeClass('slideInRight')
      .addClass('slideOutRight');

    setTimeout(() => {
      $('.region-information').hide();
    }, 300);
  });

  $(document).on('keydown', function (e) {
    if (e.key === 'Escape') {
      $('.cls-2').removeClass('active');
      $('.region-pin').removeClass('active');
      $('.region-information-container')
        .removeClass('slideInRight')
        .addClass('slideOutRight');
      let region = $(this).data('title');
      let regionElement = $('#' + region);
      regionElement.removeClass('active');
      $('.region-pin').removeClass('active');
      $('.card-information').hide();

      setTimeout(() => {
        $('.region-information').hide();
      }, 300);
    }
  });

  // toggle map and list view
  $(document).on('click', '.trigger-list', function () {
    $(this).addClass('active');
    $('.trigger-map').removeClass('active');
    $('#map').hide();
    $('#list').show();
    $('#list').css('display', 'flex');
  });

  $(document).on('click', '.trigger-map', function () {
    $(this).addClass('active');
    $('.trigger-list').removeClass('active');
    $('#list').hide();
    $('#list').css('display', 'none');
    $('#map').show();
  });

  // TEAM BLOCK
  $('.team-member-link').on('click', function (event) {
    const member = $(this).data('link');
    const memberElement = $('#' + member);

    // toggle scroll lock
    $('body').toggleClass('scroll-lock');
    if ($(memberElement).hasClass('hidden')) {
      memberElement.removeClass('hidden');
      memberElement.addClass('flex');
    } else {
      memberElement.addClass('hidden');
      memberElement.removeClass('flex');
    }
  });

  $(document).on('keydown', '.team-member-link', function (e) {
    if (e.key === 'Enter') {
      $(this).trigger('click');
      $('body').removeClass('scroll-lock');
    }
  });

  // close team member
  $(document).on('click', '.close-btn', function () {
    $('.team-member-overlay').addClass('hidden');
    $('body').removeClass('scroll-lock');
  });

  $(document).on('keydown', function (e) {
    if (e.key === 'Escape') {
      $('.team-member-overlay').addClass('hidden');
      $('body').removeClass('scroll-lock');
    }
  });

  // close team member on click outside
  $(document).on('click', function (e) {
    if (
      $(e.target).closest('.team-member-container').length === 0 &&
      $(e.target).closest('.team-member-link').length === 0
    ) {
      $('.team-member-overlay').addClass('hidden');
      $('body').removeClass('scroll-lock');
    }
  });

  // ARTICLE FILTERS
  $(document).on('click', '.filter-button', function () {
    $('.filter-button').removeClass('active');
    $(this).addClass('active');
    const filter = $(this).data('filter');

    // Hide all filtered articles and pagination elements
    $('.filtered-articles, .pagination-toggle').hide();

    // Show the targeted filtered articles and pagination
    $(`.${filter}-mode`).show();
    $(`.${filter}-pagination`).show();
  });
};

export default initUtilities;
