// uncomment for development only, turns on HMR
// import '/src/sass/app.scss';

import $ from 'jquery';
import initMenuFunctions from './menus';
import initSliders from './sliders';
import initUtilities from './utils';
import initAnimations from './animation';
import initCommerce7 from './commerce7';
import initVue from './vue';

$(document).ready(function () {
  // initialize menu functions
  initMenuFunctions();

  // initialize slider carousels
  initSliders();

  // initialize the utility functions
  initUtilities();

  // initialize the animation functions
  initAnimations();

  // initialize the commerce7 functions
  initCommerce7();

  // initialize the vue functions
  initVue();
});
