<template>
  <Commerce7Search
      :displayedProducts="products"
  />
  <div class="mb-8 flex justify-end">
    <select
      v-model="sortOrder"
      class="p-4 border rounded-md w-full lg:w-fit"
    >
      <option value="">Sort by price</option>
      <option value="low-to-high">Price: Low to High</option>
      <option value="high-to-low">Price: High to Low</option>
    </select>
  </div>
  <!-- Products Grid -->
  <div class="c7-products-container flex justify-center items-center">
    <div
      v-for="product in products"
      :key="product.id"
      class="c7-product"
    >

      <div class="c7-product__photo relative">
        <!--        <div class="c7-product__vintage relative z-0">-->
        <!--          {{ product.wine?.vintage }}-->
        <!--        </div>-->

        <div v-if="product.images && product.images.length > 0">
          <a
            aria-label="View product details"
            :href="`/product/${product.slug}`"
          >
            <img
              :src="product.images[0].src"
              :alt="product.title"
              class="c7-product-image relative z-10"
            />
            <div class="add-to-cart relative z-20">
              <a
                aria-label="View product details"
                :href="`/product/${product.slug}`"
                class="c7-product-link mb-2"
                >View details</a
              >
              <div
                v-if="product.metaData?.['availability'] !== 'Unavailable'"
                class="c7-buy-product"
                :data-product-slug="product.slug"
              ></div>
            </div>
          </a>
        </div>
      </div>
      <div class="c7-product__info">
        <p class="winery-name">{{ product.wine?.vendor }}</p>
        <a
          aria-label="View product details"
          :href="`/product/${product.slug}`"
          ><p class="text-black font-intertight text-lg">
            {{ product.title }}
          </p></a
        >

        <p class="font-veryvogue text-darkgreen text-lg">
          {{ product.metaData?.['appellation'] || product.wine?.appellation }}
        </p>
        <div class="flex justify-center mt-2 w-full">
          <div class="flex flex-col px-5 border-r border-gray-300" v-if="product.metaData?.['bottles-per-crate']">
            <div>
              ${{ (((product.variants[0].price / 100).toFixed(2)) / product.metaData["bottles-per-crate"]).toFixed(2) }}
            </div>
            <div class="mt-0 text-gray-500">per bottle</div>
          </div>
          <div class="flex flex-col px-5">
            <div
              v-if="product.variants?.length"
            >
              ${{ (product.variants[0].price / 100).toFixed(2) }}
            </div>
            <div class="mt-0 text-gray-500">per case</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import Commerce7Search from "./Commerce7Search.vue";

const props = defineProps({
  products: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const sortOrder = ref(
  new URLSearchParams(window.location.search).get('sort') || ''
);
const handleSort = value => {
  const params = new URLSearchParams(window.location.search);
  params.set('sort', value);
  params.set('page', '1');
  window.location.search = params.toString();
};

watch(sortOrder, newValue => {
  handleSort(newValue);
});
</script>
