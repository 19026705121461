<template>
  <div
    class="c7-pagination"
    v-if="totalPages > 1"
  >
    <div class="pagination">
      <a
        :class="{ disabled: currentPage === 1 }"
        :href="getPageUrl(currentPage - 1)"
        >Previous</a
      >
      <div class="pagination-buttons">
        <a
          v-for="page in totalPages"
          :key="page"
          :class="{ active: page === currentPage }"
          class="pagination-button"
          :href="getPageUrl(page)"
        >
          {{ page }}
        </a>
      </div>
      <a
        aria-label="Next"
        :class="{ disabled: currentPage === totalPages }"
        :href="getPageUrl(currentPage + 1)"
        >
        Next
      </a>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  currentPage: Number,
  totalPages: Number,
});

const getPageUrl = page => {
  const params = new URLSearchParams(window.location.search);
  params.set('page', page);
  return `?${params.toString()}`;
};
</script>
