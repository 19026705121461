<template>
  <div id="commerce7-container">

    <div v-if="contentLoader" class="block-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div v-else class="flex flex-col gap-10 lg:flex-row">
      <Commerce7Filters
          v-if="filterCollections"
          :toggleFilter="toggleFilter"
          :toggleFilterValue="toggleFilterValue"
          :clearFilter="clearFilter"
          :selectedFilters="selectedFilters"
          :activeFilters="activeFilters"
          :allCollections="allCollections"
          :baseCollectionId="collectionId"
          :filterCollections="filterCollections"
      />
      <div
          class="w-full lg:w-10/12 h-full flex flex-col"
      >
        <Commerce7Products
            :products="displayedProducts"
        />
        <div
            v-show="!displayedProducts.length && !contentLoader"
            class="w-full lg:w-[80%] text-lg flex flex-col gap-4 ml-10"
        >
          <h4 class="text-4xl">No products found</h4>
          <p>
            We couldn't find any products matching your search. Please try again.
          </p>
          <a
              class="btn solid-blue w-fit"
              href="/shop/products"
              aria-label="Clear Filter"
          >Clear Filter</a
          >
        </div>
      </div>
    </div>
    <Commerce7Pagination
        :current-page="currentPage"
        :total-pages="Math.ceil(totalProducts / itemsPerPage)"
    />
  </div>
</template>


<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';

import Commerce7Products from './Commerce7Products.vue';
import Commerce7Pagination from './Commerce7Pagination.vue';
import Commerce7Filters from './Commerce7Filters.vue';

const contentLoader = ref(true);
const currentPage = ref(1);
const displayedProducts = ref([]);
const totalProducts = ref(0);
const itemsPerPage = 12;
const collectionId = ref(null);
const allCollections = ref([]);
const filterCollections = ref({});
const activeFilters = ref({});
const selectedFilters = ref({});
const collectionsLoaded = ref(false);

// Helper function to get URL params
const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    page: parseInt(params.get('page')) || 1,
    collectionId: params.get('collectionId'),
    q: params.get('q'),
    sort: params.get('sort'),
  };
};

const fetchProducts = async () => {
  try {
    const params = getUrlParams();

    const requestParams = {
      page: params.page,
      collectionId: params.collectionId,
      q: params.q,
      limit: itemsPerPage,
      sort: params.sort,
    };

    const response = await axios.get(
        '/actions/commerce7/products/get-products',
        { params: requestParams }
    );

    if (response.data.success) {
      displayedProducts.value = response.data.data.products;
      totalProducts.value = response.data.data.total;
      currentPage.value = params.page;
    }
  } catch (error) {
    // Handle error silently as in original code
  }
};

const fetchCollections = async () => {
  // Skip if collections are already loaded
  if (collectionsLoaded.value) return;

  try {
    const response = await axios.get(
        '/actions/commerce7/collection/get-collections'
    );

    allCollections.value = response.data.data;
    processCollections(response.data.data);
    collectionsLoaded.value = true;

    // Initialize filters based on URL params
    initializeFilters();
  } catch (error) {
    console.error('Error fetching collections:', error);
  }
};

const processCollections = (collections) => {
  // Clear existing data to avoid duplicates during reprocessing
  filterCollections.value = {};

  // Process each collection
  collections.forEach(collection => {
    const filterHeader = collection.metaData?.['collection-category'];
    if (filterHeader?.trim()) {
      addFilterOption(filterHeader, collection);
    }
  });

  // Sort the filter categories
  sortFilterCollections();
};

const sortFilterCollections = () => {
  const sortedFilterCollections = {};
  const orderPriority = {
    'Country': 1,
    'Varietal': 2,
    'Type': 3,
    'Winery': 4,
    'Format': 5,
    'Practices': 6,
  };

  Object.keys(filterCollections.value)
      .sort((a, b) => (orderPriority[a] || 99) - (orderPriority[b] || 99))
      .forEach(key => {
        sortedFilterCollections[key] = filterCollections.value[key];
      });

  filterCollections.value = sortedFilterCollections;
};

const addFilterOption = (filterHeader, collection) => {
  if (!filterCollections.value[filterHeader]) {
    filterCollections.value[filterHeader] = {
      metaData: {
        'collection-category': filterHeader,
      },
      collections: [],
    };
    activeFilters.value[filterHeader] = false;
  }
  filterCollections.value[filterHeader].collections.push(collection);

  // Sort collections if this is the Format filter
  if (filterHeader === 'Format') {
    filterCollections.value[filterHeader].collections.sort((a, b) => {
      const order = {
        '750ml': 1,
        '375ml': 2,
        '1500ml': 3,
        '6 Bottle Case': 4,
        '12 Bottle Case': 5
      };
      return (order[a.title] || 99) - (order[b.title] || 99);
    });
  }
};

const initializeFilters = () => {
  const params = getUrlParams();
  const urlCollectionId = params.collectionId;

  if (urlCollectionId) {
    Object.entries(filterCollections.value).forEach(([key, values]) => {
      const collection = values.collections.find(c => c.id === urlCollectionId);
      if (collection) {
        activeFilters.value[key] = true;
        selectedFilters.value[key] = urlCollectionId;
      }
    });
  }
};

const updateProducts = async newCollectionId => {
  const params = new URLSearchParams(window.location.search);
  params.set('collectionId', newCollectionId);
  params.set('page', '1');
  window.history.pushState({}, '', `?${params.toString()}`);
  collectionId.value = newCollectionId;
  await fetchProducts();
};

const toggleFilter = key => {
  activeFilters.value[key] = !activeFilters.value[key];
};

const toggleFilterValue = async (key, collectionId) => {
  selectedFilters.value[key] = collectionId;
  updateProducts(collectionId);
};

const clearFilter = key => {
  const params = new URLSearchParams(window.location.search);

  // Clear the filter
  delete selectedFilters.value[key];
  params.delete('collectionId');
  params.set('page', '1');

  // Preserve search query if it exists
  const searchQuery = params.get('q');
  if (searchQuery) {
    params.set('q', searchQuery);
  }

  window.location.search = params.toString();
};

// Initial data loading
const loadInitialData = async () => {
  contentLoader.value = true;

  try {
    // Load products and collections concurrently
    await Promise.all([
      fetchProducts(),
      fetchCollections()
    ]);
  } catch (error) {
    console.error('Error loading initial data:', error);
  } finally {
    contentLoader.value = false;
  }
};

onMounted(loadInitialData);
</script>
