export const initCommerce7 = () => {
   $(document).ready(function() {
      // Add search form handling
      $('.search-form').on('submit', function(e) {
         e.preventDefault();

         const searchQuery = $(this).find('input[name="q"]').val().trim();
         const collectionPath = '/our-portfolio';

         if (searchQuery) {
            if (window.location.pathname === collectionPath) {
               const params = new URLSearchParams(window.location.search);
               params.set('q', searchQuery);
               window.location.search = params.toString();
            } else {
               window.location.href = `${collectionPath}?q=${encodeURIComponent(searchQuery)}`;
            }
         }
      });

      setTimeout(() => {
         const bottlePriceContainer = $('#bottle-price-value');
         const numberOfBottlesText = $('#bottles-per-crate').text();
         const numberOfBottles = parseInt(numberOfBottlesText, 10);

         const casePriceText = $('.custom-product .prices .c7-product__add-to-cart__price').text();
         const priceMatch = casePriceText.match(/\$?([\d,]+\.?\d*)/);
         const numericPrice = priceMatch ? parseFloat(priceMatch[1].replace(',', '')) : 0;

         const bottlePrice = numericPrice / numberOfBottles;
         bottlePriceContainer.text(bottlePrice.toFixed(2));
      }, 3000);

      // Existing loading animation code
      const loadingHTML = `
         <div class="c7-loading-animation">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a10" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#152B35"></stop><stop offset=".3" stop-color="#152B35" stop-opacity=".9"></stop><stop offset=".6" stop-color="#152B35" stop-opacity=".6"></stop><stop offset=".8" stop-color="#152B35" stop-opacity=".3"></stop><stop offset="1" stop-color="#152B35" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a10)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#152B35" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
         </div>
      `;

      $('.target-hide').hide();
      $('.slider-view').prepend(loadingHTML);

      setTimeout(() => {
         $('.c7-loading-animation').remove();
         $('.target-hide').show();
      }, 3500);
   });
};

export default initCommerce7;
