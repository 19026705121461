import $ from "jquery";
import "slick-carousel";

window.jQuery = window.$ = $;
export const initSliders = () => {
    const slider = $(".slider");
    slider.slick({
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        focusOnChange: true,
        accessibility: true,
    });

    $(document).ready(function() {
        const buttons = $('.slider').find('button');
        buttons.attr('tabindex', '0');

        setTimeout(() => {
        const productSlider = $('.slider-view .c7-product-collection__product-list');
        productSlider.slick({
            arrows: true,
            draggable: false,
            infinite: true,
            accessibility: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        $('.c7-loading-animation').remove();
        $('.c7-product-collection').show();
        }, 3000);
    });
};

export default initSliders;
