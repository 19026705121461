<template>
    <!-- Search Results Message -->
    <div class="search-message-container">
      <div v-if="searchQuery" class="search-results-message py-4 text-center font-semibold mb-4 rounded-lg"
           :class="{
             'success-search': displayedProducts?.length > 0,
             'failed-search hidden': !displayedProducts?.length
           }">
        {{ searchResultsMessage }}
        <button @click="clearSearch" aria-label="Clear search" class="clear-search ml-4 text-sm underline">
          Clear search
        </button>
      </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
const searchQuery = ref('');
const searchResultsMessage = ref('');

defineProps({
  displayedProducts: {
    type: Array,
    required: true,
    default: () => []
  }
});

const clearSearch = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('q');
  history.pushState({}, '', window.location.pathname + '?' + params.toString());
  window.location.reload();
};

// Get search query from URL
const getSearchFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const queryParam = params.get('q');

    if (queryParam) {
        searchQuery.value = queryParam;
        searchResultsMessage.value = 'Search results for : ' + queryParam;
    } else {
        searchResultsMessage.value = 'No search results found';
    }


};

onMounted(() => {
    getSearchFromUrl();
});

</script>
