import AOS from "aos";

export const initAnimations = () => {
  initAOS();
};

function initAOS() {
  AOS.init({
    offset: 100,
    duration: 500,
    delay: 20,
    once: true,
    disable: true, // disable for dev
  });
}

export default initAnimations;
